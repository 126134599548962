<template>
  <button :disabled="isDisabled" @click="$emit('onClick')" :class="buttonType">
    {{ text }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Button',
  emits: ['onClick'],
  props: {
    buttonType: {
      type: String,
      default: ''
    },
    text: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="sass" scoped>
button
  display: flex
  padding: 0.6em 1.4em
  border: none
  border-radius: 3.125em
  font-family: "Faro-Variable"
  font-size: 16px
  justify-content: center
  align-items: center
  line-height: 150%
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
.clear_effects
  width: auto
  padding: 0
  font-size: 14px
  background: transparent
  border-radius: 4px
  border: none
  color: $colorCoolGray
  line-height: 150%
  text-decoration: underline
  text-underline-offset: 2px
  @media screen and (max-width: $xsBreakpoint)
    order: 1
    width: 50%
    justify-content: flex-start
.playbutton
  width: 103px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: bold
  &:before
    content: ""
    width: 12px
    height: 12px
    background: url('~@/assets/playbutton.svg') no-repeat left
    margin-right: 4px
  &:disabled
    background: $colorFogGray
    color: $colorCoolGray

  @media screen and (max-width: $xsBreakpoint)
    width: calc( 50% - 8px )
button.playbutton:disabled:before
  background: url('~@/assets/playdisabled.svg') no-repeat left

.play-selection_button
  width: 153px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: bold
  &:before
    content: ""
    width: 12px
    height: 12px
    background: url('~@/assets/playbutton.svg') no-repeat left
    margin-right: 4px
  &:disabled
    background: $colorFogGray
    color: $colorCoolGray

  @media screen and (max-width: $xsBreakpoint)
    width: calc( 50% - 8px )
button.play-selection_button:disabled:before
  background: url('~@/assets/playdisabled.svg') no-repeat left

.downloadbutton
  width: 126px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: bold
  height: 100%
  &:before
    content: ""
    width: 12px
    height: 12px
    background: url('~@/assets/downloadbutton.svg') no-repeat left
    margin-right: 4px
  &.disabled
    background: $colorFogGray
    color: $colorCoolGray
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
button.downloadbutton.disabled:before
  background: url('~@/assets/downloaddisabled.svg') no-repeat left

.savebutton
  width: 126px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: bold
  &:before
    content: ""
    width: 12px
    height: 12px
    background: url('~@/assets/downloadbutton.svg') no-repeat left
    margin-right: 4px
  &.disabled
    background: $colorFogGray
    color: $colorCoolGray
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
button.savebutton.disabled:before
  background: url('~@/assets/downloaddisabled.svg') no-repeat left
.registerbutton
  width: 150px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: bold
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
.loginbutton
  width: auto
  background: $colorWhite
  color: $colorSeriousBlue
  border: 1px solid $colorSeriousBlue
  font-weight: 700
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
.errorOkbutton
  width: 90px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: normal
  float: right
.buyplanbutton
  width: 90px
  background: $colorHappyYellow
  color: $colorSeriousBlue
  border-radius: 4px
  font-size: 16px
  font-weight: normal
  float: right
</style>
