
import { defineComponent } from 'vue'
import Button from '@/components/UI/Button.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const routeQuery = router.currentRoute.value.query
    const goToDashboard = () => {
      if (routeQuery.projectId && routeQuery.token) {
        router.push({
          name: 'VoiceOverGenerator',
          query: {
            projectId: routeQuery.projectId,
            token: routeQuery.token,
            lang: routeQuery.lang,
            apiUrl: routeQuery.apiUrl
          }
        })
      } else if (!routeQuery.projectId && routeQuery.token) {
        router.push({
          name: 'VoiceOverGenerator',
          query: { token: routeQuery.token, lang: routeQuery.lang, apiUrl: routeQuery.apiUrl }
        })
      } else if (!routeQuery.projectId || !routeQuery.token) {
        router.push({ name: 'VoiceOverGenerator', query: { lang: routeQuery.lang, cur: routeQuery.cur } })
      }
      // router.push({ name: 'VoiceOverGenerator' })
    }
    return { goToDashboard }
  },
  components: {
    Button
  }
})
